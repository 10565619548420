// User GET
import ReactGa from "react-ga";
import { TrackingID } from "../../Config/config";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MPFM6BQB", // Replace with your GTM ID
};

TagManager.initialize(tagManagerArgs);

const fireEvent = (eventData) => {

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "Payment Completion", // Replace with your custom event name
  });
  console.log("Data Layer Event Fired:", window.dataLayer);
  localStorage.setItem("secretEvent", "fired_event");

  // // Set timeout to delay the redirect
  // setTimeout(() => {
  //   window.location.href = "https://calendly.com/bill-williamhorourke";
  // }, 100); 
};


export const getUserInfo = ({
  userData,
  setUserData,
  setActiveStep,
  setSSN,
  setFormData,
  setApplicants,
  setCompanyApplicants,
  shouldReloadSingleFile,
}) => {
  const {
    step,
    strip_payment,
    Application_completed,
    securityKey,
    beneficial_owners,
    ...rest
  } = userData;

  setUserData(userData);

  const currentStep = step || 0;
  const eventFired = localStorage.getItem("secretEvent");
  if (currentStep === 0) {
    setActiveStep(currentStep + 1);
  } else if (userData && strip_payment !== null) {
   
    // setOpenModal(true);
    // ReactGa.initialize(TrackingID);

    // ReactGa.event({
    //   category: "Pyament",
    //   action: "Payment Completed",
    //   label: "Payment has been completed by user",
    // });

    if (eventFired !== "fired_event") {
      fireEvent({
        category: "User",
        action: "Payment completed",
        label: "payment",
      });
      setActiveStep(5);
    }else {
      setActiveStep(5);
       
     
    }

  } else {
    setActiveStep(currentStep);
  }

  setSSN(securityKey);
  // setFormData(prevData => ({ ...prevData, ...rest }));
  setFormData((prevData) => ({
    ...prevData,
    name: userData.name || prevData.name,
    email: userData.email || prevData.email,
    phone: userData.phone || prevData.phone,
    businessName: userData.business_name || prevData.businessName,
    company_name: userData.business_address || prevData.business_address,
    businessAddress: userData.know_about_us || prevData.know_about_us,
    trade_name: userData.trade_name || prevData.trade_name,
    business_StAddress:
      userData.business_StAddress || prevData.business_StAddress,
    state_registration:
      userData.state_registration || prevData.state_registration,
    date_of_formation: userData.date_of_formation || prevData.date_of_formation,
    taxpayer_idNum: userData.taxpayer_idNum || prevData.taxpayer_idNum,
    applicant_name: userData.applicant_name || prevData.applicant_name,
    applicant_dob: userData.applicant_dob || prevData.applicant_dob,
    applicant_address: userData.applicant_address || prevData.applicant_address,
    applicant_passportNum:
      userData.applicant_passportNum || prevData.applicant_passportNum,
    //   isCheckedStepThree: userData.self_employed_from === "Yes" ? true : false || false,
    // Add other fields accordingly
  }));

  if (beneficial_owners?.length > 0 && !shouldReloadSingleFile) {
    setApplicants(
      beneficial_owners.map((applicant) => ({
        ...applicant,
        driver_licence_front: applicant.driver_licence_front,
        driver_licence_front_name: applicant.driver_licence_front_name,
        driver_licence_back: applicant.driver_licence_back,
        driver_licence_back_name: applicant.driver_licence_back_name,
      }))
    );
  }

  setCompanyApplicants((prevSelectedFiles) => ({
    ...prevSelectedFiles,
    applicant_driver_licence_front: userData.driver_licence_front,
    applicant_driver_licence_back: userData.driver_licence_back,
  }));
};
