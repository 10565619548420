import React from 'react'

const StepOne = ({ formData, errors, handleInputChange, handleNext, loader , toggle}) => {
  console.log(toggle)
    return (
    <div class="container mt-5"  >
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-8">
        <form id="regForm border-0" >
       
          

        <div className="container">
  <div className="row justify-content-center">
    <div className="col-lg-9 col-md-12 col-sm-12">
      <div className="card" style={{ marginTop: 70 }}>
        <div className="card-body">
          <h5 className="card-title">{toggle ? "Pasos:" : "Steps:"}</h5>
          <ol className="step-list">
            <li>{toggle ? "Firme el acuerdo con el cliente, autorizándonos a ayudarle en la presentación electrónica de su BOIR." : "Sign the client agreement, authorizing us to assist you in the e-filing of your BOIR."}</li>
            <li style={{ marginTop: 12 }}>{toggle ? "Paga la tarifa de presentación de $200." : "Pay the $200 filing fee."}</li>
            <li style={{ marginTop: 12 }}>{toggle ? "Confirme que ha recopilado y tiene disponible de inmediato para su llamada por Zoom con nuestro experto en FinCEN toda la información requerida de BOI." : "Confirm that you will have collected and have immediately available for your Zoom call with our FinCEN expert all of the required BOI."}</li>
            <li style={{ marginTop: 12 }}>{toggle ? "Programe su cita de 30 minutos por Zoom en nuestro calendario en línea con uno de nuestros expertos en FinCEN." : "Schedule your 30-minute Zoom call appointment on our online calendar with one of our FinCEN experts."}</li>
            <li style={{ marginTop: 12 }}>{toggle ? "Esté disponible en línea en la fecha y hora seleccionadas para su cita por Zoom con uno de nuestros expertos en FinCEN." : "Be available online on your selected date and time for your Zoom call appointment with one of our FinCEN experts."}</li>
            <li style={{ marginTop: 12 }}>{toggle ? "Permita que nuestro experto en FinCEN le ayude a navegar el sitio web fincen.gov y completar su presentación." : "Let our FinCEN expert assist you in navigating the fincen.gov filing website and completing your filing."}</li>
            <li style={{ marginTop: 12 }}>{toggle ? "Recibirá la confirmación de que la presentación se ha completado y enviará una copia por correo electrónico a su experto en FinCEN." : "You will receive confirmation of a completed filing and email a copy to your FinCEN expert."}</li>

          </ol>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
            <button disabled={loader} type="button" className="btn btn-lg custom-btn" onClick={handleNext}>
              {toggle ? "Aceptar" : "OK"}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </form>
      </div>
    </div>
  </div>
  )
}

export default StepOne
