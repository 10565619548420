// import React from "react";
// import Confetti from "react-confetti";
// import gifTick from "../images/gif-submit.gif";
// import { ToggleContext } from "../../ToggleContext";
// import { useContext, useEffect } from "react";
// import CustomCssLoader from "../../components/LoadingScreen/CustomcssLoader";
// import axios from "../../../src/axios/axios"
// const StepFive = () => {
//   const width = 800; // Set your desired width here
//   const height = 600; // Set your desired height here
//   const confettiStyle = {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     overflow: "hidden",
//     pointerEvents: "none",
//   };

//   const boxStyle = {
//     position: "relative",
//     width: "100%",
//     maxWidth: "1500px",
//     boxShadow: "0 0 5px 5px rgb(60 125 147 / 30%)",
//     borderRadius: "8px",
//     margin: "0 auto",
//     padding: "130px 20px",
//     display: "block",
//   };

//   const { toggle, setToggle } = useContext(ToggleContext);

//   // useEffect(() => {
//   //   const timer = setTimeout(() => {
//   //     window.location.href = "https://fincensafe.com";
//   //   }, 1000); // 3000ms = 3 seconds

//   //   return () => clearTimeout(timer);
//   // }, []);
//   // useEffect(() => {
//   //   const fetchUserData = async () => {
//   //     const token = localStorage.getItem("token");
//   //     if (!token) return;

//   //     try {
        

//   //       const response = await axios.get("user/getUser");
//   //       if (response.status === 200) {
//   //         const userData = response.data;
//   //         if (userData?.strip_payment !== null) {
//   //           window.location.href = "https://fincensafe.com";
//   //         }
//   //       } else {
//   //         console.error("Non-200 status while fetching user data");
//   //       }
//   //     } catch (error) {
//   //       console.error("Error fetching user data", error);
//   //     } finally {
       
//   //     }
//   //   };

//   //   fetchUserData();
//   // }, []);
//   return (
//     <div class="container mt-5">
//       <div class="row d-flex justify-content-center align-items-center">
//         <div class="col-md-8">
//           <form id="regForm" className="border-0" style={{ marginTop: "40px" }}>
//             {/* <h1 id="register mt-3" style={{marginTop: 40, color: '#0097B2'}}>
//             Thanks for your valuable information.
//           </h1> */}

//             <div style={boxStyle}>
//             <CustomCssLoader />
//               {/* <div style={{ display: "flex", justifyContent: "center" }}>
//                 <img
//                   src={gifTick}
//                   style={{
//                     width: "100px",
//                     height: "100px",
//                     justifyContent: "center",
//                     display: "flex",
//                   }}
//                 />
//               </div>
//               <Confetti width={width} height={height} style={confettiStyle} />
//               <div style={{ textAlign: "center" }}>
//                 <h1 style={{ color: "#20A4BB" }}>
//                   {toggle ? "Felicidades!" : "Congratulations!"}
//                 </h1>
//                 <h3 style={{ marginTop: 3 }}>
//                   {toggle
//                     ? "Su solicitud ha sido enviada"
//                     : "Your application has been submitted"}
//                 </h3>
//               </div> */}
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StepFive;




import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CalendlyTopbar from "../../components/Navbar/CalendlyTopbar"

const StepFive = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     if (event.data.event && event.data.event.indexOf('calendly') === 0) {
  //       // Calendly event received
  //       console.log('Calendly event:', event.data.event);
  //       if (event.data.event === 'calendly.event_scheduled') {
  //         // Handle successful scheduling
  //         console.log('Event scheduled');
  //         // You can add additional logic here, like showing a success message
  //       }
  //     }
  //   };

  //   window.addEventListener('message', handleMessage);

  //   return () => {
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);

  // const handleBack = () => {
  //   navigate('/');
  // };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* <button onClick={handleBack} style={{ margin: '10px', alignSelf: 'flex-start' }}>
        Back to Home
      </button> */}
       <CalendlyTopbar/>
      <iframe
        src="https://calendly.com/bill-williamhorourke"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default StepFive;


// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { 
//   Button, 
//   Dialog, 
//   DialogActions, 
//   DialogContent, 
//   DialogContentText, 
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import CalendlyTopbar from "../../components/Navbar/CalendlyTopbar";


// const CalendlyIframe = () => {
//   const navigate = useNavigate();
//   const [openModal, setOpenModal] = useState(false);
//   const [showIframe, setShowIframe] = useState(false);

//   useEffect(() => {
//     const hasSeenPrecautions = localStorage.getItem('hasSeenPrecautions');
//     if (!hasSeenPrecautions) {
//       setOpenModal(true);
//     } else {
//       setShowIframe(true);
//     }
//   }, []);

//   // const handleBack = () => {
//   //   navigate('/');
//   // };

//   const handleCloseModal = () => {
//     localStorage.setItem('hasSeenPrecautions', 'true');
//     setOpenModal(false);
//     setShowIframe(true);
//   };

//   const handleCrossIcon = () => {
//     setOpenModal(false);
//     setShowIframe(true);
//   };

//   const precautions = [
//     "Ensure you're in a quiet, well-lit environment for the meeting.",
//     "Have all necessary documents and information ready before the call.",
//     "Test your camera and microphone before the scheduled time.",
//     "Be prepared to discuss your business structure and beneficial ownership.",
//     "Have a form of identification ready, if required.",
//     "Ensure you have a stable internet connection.",
//     "Dress professionally as you would for an in-person meeting.",
//     "Be punctual and log in a few minutes before the scheduled time.",
//     "Have a notepad ready to take notes during the meeting.",
//     "If you need to reschedule, please do so at least 24 hours in advance."
//   ];

//   return (
//     <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
//       {/* <Button onClick={handleBack} style={{ margin: '10px', alignSelf: 'flex-start' }}>
//         Back to Home
//       </Button> */}
//        <CalendlyTopbar/>
//       <Dialog
//         open={openModal}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title" style={{ paddingRight: '48px', color: '#0499B3', fontWeight: 600 }}>
//           {"Important Precautions for Your Meeting"}
//           <IconButton
//             aria-label="close"
//             onClick={handleCloseModal}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description" style={{fontStyle: 'italic'}}>
//             Please review the following precautions before your meeting:
//           </DialogContentText>
//           <List>
//             {precautions.map((precaution, index) => (
//               <ListItem key={index}>
//                 <ListItemText primary={`${index + 1}. ${precaution}`} />
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions style={{ justifyContent: 'center', padding: '16px 0' }}>
//           <Button 
//             onClick={handleCloseModal} 
//             style={{ 
//               backgroundColor: '#505960', 
//               color: 'white',
//               padding: '10px 30px',
//               fontSize: '16px',
//               fontWeight: 'bold',
//               textTransform: 'none',
//               borderRadius: '8px',
//             }}
//           >
//             I Understand
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {showIframe && (
//         <iframe
//           src="https://calendly.com/bill-williamhorourke"
//           width="100%"
//           height="100%"
//           frameBorder="0"
//         ></iframe>
//       )}
//     </div>
//   );
// };

// export default CalendlyIframe;